import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { colours } from '../assets/theme';
import Link from '../components/atoms/Link';
import { TextP, Contents, Header } from './confirm-email-sub';
import Page from '../components/organisms/Page';
import { useLangStore } from '../store';
import { useTranslation } from '../hooks/TranslationHooks';

const UnsubscribePage = () => {
  const [pageState, setPageState] = useState('loading');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      const reset = urlParams.get('reset');

      const unsubscribe = async () => {
        try {
          const { status } = await axios.delete(`https://cms.looseantenna.fm/subscribers/unsubscribe?id=${id}&reset=${reset}`);
          if (status === 200) {
            setPageState('success');
          }
        } catch (err) {
          console.log({ err });
          setPageState('invalid');
        }
      };

      if (!id || !reset) {
        setPageState('invalid');
      } else {
        unsubscribe();
      }
    }
  }, []);

  return (
    <Page title="Unsubscribe" noindex>
      <Contents>
        <div>
          <PageInner pageState={pageState} />
          <Link to="/">
            <TextP align="center" colour={colours.blue}>
              → Go home
            </TextP>
          </Link>
        </div>
      </Contents>
    </Page>
  );
};

const PageInner = ({ pageState }) => {
  const currentLang = useLangStore(store => store.currentLang);
  const successHeaderMessage = useTranslation({ key: 'unsubscribeSuccessfulHeader' })[currentLang];
  const successMessage = useTranslation({ key: 'unsubscribeSuccessfulMessage' })[currentLang];
  const invalidRequestHeaderMessage = useTranslation({ key: 'invalidRequest' })[currentLang];
  const invalidRequestMessage = useTranslation({ key: 'invalidRequestMessage' })[currentLang];

  if (pageState === 'invalid') {
    return (
      <>
        <Header align="center">Error: {invalidRequestHeaderMessage}</Header>
        <TextP align="center">{invalidRequestMessage}</TextP>
      </>
    );
  }
  if (pageState === 'success') {
    return (
      <>
        <Header align="center">{successHeaderMessage}</Header>
        <TextP align="center">{successMessage}</TextP>
      </>
    );
  }

  return null;
};

export default UnsubscribePage;
